import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const markdown = `
### Stock Overview: Reliance Industries Limited

**Company Overview**
- **Name**: Reliance Industries Limited
- **Symbol**: RELIANCE
- **Industry**: Refineries & Marketing
- **Sector**: Oil, Gas & Consumable Fuels
- **Listing Date**: 29-Nov-1995

**Market Overview**
- **Current Price**: ₹1,338.4 with an increase of +6.35 (0.48%)
- **Day Range**: ₹1,333 - ₹1,341.95
- **52 Week Range**: ₹1,174.6 - ₹1,608.8

**Stock Price vs DMA**
This chart shows the stock's daily closing prices alongside the 50-day and 200-day moving averages (DMA). The recent trend indicates a declining price trajectory below both the 50-DMA and 200-DMA, suggesting a bearish outlook in the short term.

**Annual Income Statement (in Cr)**
For the fiscal years 2020 to 2024:
- **Revenue**: Grew steadily, reaching ₹5,474,620 Cr in 2024.
- **Net Profit**: Has shown a consistent increase, indicating healthy financial performance.
- **Operating and Net Profit Margins**: OPM at 15.77% and NPM at 7.67% in 2024 show strong profitability.

**Quarterly Income Statement (in Cr)**
This statement highlights the financials for the most recent quarters. Notably:
- **Revenue**: Slightly fluctuating across quarters.
- **Net Profit**: Strong profit growth observed, indicating resilience.

**Board Meetings**
Key meetings for financial disclosures and dividend decisions are listed, with the most recent held on Oct 14, 2024.

**Corporate Actions**
Includes information on bonuses, dividends, and rights issues. The latest action in Oct 2024 was a 1:1 bonus issue, which could attract more investors by increasing liquidity.

**Latest Announcements**
Highlights recent announcements related to share certificates, credit ratings, and acquisition/disposal updates, providing insights into the company’s administrative updates.

**Financial Reports**
Quarterly financial reports are available, with the latest report dated Sep 2024.

**Shareholding Pattern**
Displays the ownership distribution among promoters, the public, and employee trusts. Promoters hold about 50.31%, while the public holds 49.69%.

---

### Future Prediction of Stock Performance
Based on the current stock price trend and the moving averages, Reliance Industries Limited's stock shows a bearish sentiment in the short term. However, the consistent financial growth in revenue and profit indicates a solid long-term outlook, especially if the company maintains its strong financial position and profitability. Investors may want to monitor quarterly financial results and upcoming corporate actions for further insights.
`;
const MarkdownViewer = () => {
	return (
		<div className="markdown-viewer">
			<ReactMarkdown
				remarkPlugins={[remarkGfm]}
				components={{
					// Override default components to apply styles
					h1: ({ node, ...props }) => (
						<h1 className="text-2xl font-bold mb-4" {...props} />
					),
					h2: ({ node, ...props }) => (
						<h2 className="text-xl font-bold mb-3" {...props} />
					),
					h3: ({ node, ...props }) => (
						<h3 className="text-lg font-bold mb-2" {...props} />
					),
					p: ({ node, ...props }) => <p className="mb-2" {...props} />,
					code: ({ node, ...props }) => (
						<code className="bg-gray-200 px-2 py-1 rounded" {...props} />
					),
					pre: ({ node, ...props }) => (
						<pre className="bg-gray-200 p-4 rounded mb-4" {...props} />
					),
					img: ({ node, ...props }) => (
						<img className="max-w-full" {...props} />
					),
					// Handle preserving spaces
					text: ({ node, ...props }) => (
						<span>
							{node.value.replace(/\s/g, (space) =>
								space === " " ? <Space className="inline" /> : space
							)}
						</span>
					),
				}}>
				{markdown}
			</ReactMarkdown>
		</div>
	);
};

export default MarkdownViewer;
