import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const StockChart = ({ data }) => {
	// Extract dates and values for Price, 50 DMA, and 200 DMA
	const labels = data.datasets[0].values.map((item) => item[0]);
	const priceData = data.datasets[0].values.map((item) => parseFloat(item[1]));
	const dma50Data = data.datasets[1].values.map((item) => parseFloat(item[1]));
	const dma200Data = data.datasets[2].values.map((item) => parseFloat(item[1]));

	// Chart data configuration
	const chartData = {
		labels: labels,
		datasets: [
			{
				label: "Price on NSE",
				data: priceData,
				borderColor: "rgba(75, 192, 192, 1)",
				backgroundColor: "rgba(75, 192, 192, 0.2)",
				fill: true,
			},
			{
				label: "50 DMA",
				data: dma50Data,
				borderColor: "rgba(255, 206, 86, 1)",
				backgroundColor: "rgba(255, 206, 86, 0.2)",
				fill: true,
			},
			{
				label: "200 DMA",
				data: dma200Data,
				borderColor: "rgba(153, 102, 255, 1)",
				backgroundColor: "rgba(153, 102, 255, 0.2)",
				fill: true,
			},
			// {
			// 	label: "Volume",
			// 	data: volumeData,
			// 	type: "bar", // Representing volume as bars
			// 	fill: true,
			// },
		],
	};

	// Chart options
	const options = {
		responsive: true,
		scales: {
			x: {
				title: {
					display: true,
					text: "Date",
				},
			},
			y: {
				title: {
					display: true,
					text: "Value",
				},
			},
		},
	};

	return (
		<div>
			<h2>Stock Price vs DMA </h2>
			<Line height={70} data={chartData} options={options} />
		</div>
	);
};

export default StockChart;
