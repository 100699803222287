import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Divider,
  Link,
  Stack,
} from '@mui/material';
import {
  AppBar,
  Toolbar,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import { styled } from '@mui/material/styles';
import StockChart from './chart';
import { TICKER_LIST } from './menubar';
import { ApiResponse, CorporateAction, LatestAnnouncement, MarketData, ReportUrl, TableData, PeerGroup } from './types';
import MarkdownViewer from './MarkdownViewer';


const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  '& .MuiCardHeader-root': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  }
}));


const Base = () => {
  const [selectedTicker, setSelectedTicker] = useState("");
  const [apiResponse, setApiResponse] = useState<ApiResponse | null>(null);

  const [basicInfo, setBasicInfo] = useState<Record<string, string> | null>(null);
  const [marketData, setMarketData] = useState<MarketData | null>(null);

  const [peerGroups, setPeerGroups] = useState<PeerGroup[] | null>(null);
  const [corporateActions, setCorporateActions] = useState<CorporateAction[] | null>(null);
  const [latestAnnouncements, setLatestAnnouncements] = useState<LatestAnnouncement[] | null>(null);
  const [shareholdingData, setShareHoldingData] = useState<string | null>(null);

  const [tableData, setTableData] = useState<TableData | null>(null);
  const [reportsUrls, setReportsUrls] = useState<ReportUrl[] | null>(null);

  const handleTickerChange = (event: any) => {
    setSelectedTicker(event.target.value);
  };

  const stockData = {
    "datasets": [
      {
        "metric": "Price",
        "label": "Price on NSE",
        "values": [
          [
            "2024-05-07",
            "1401.48"
          ],
          [
            "2024-05-08",
            "1418.55"
          ],
          [
            "2024-05-09",
            "1394.13"
          ],
          [
            "2024-05-10",
            "1407.43"
          ],
          [
            "2024-05-13",
            "1402.70"
          ],
          [
            "2024-05-14",
            "1420.08"
          ],
          [
            "2024-05-15",
            "1416.28"
          ],
          [
            "2024-05-16",
            "1425.35"
          ],
          [
            "2024-05-17",
            "1435.70"
          ],
          [
            "2024-05-18",
            "1434.83"
          ],
          [
            "2024-05-21",
            "1436.13"
          ],
          [
            "2024-05-22",
            "1460.65"
          ],
          [
            "2024-05-23",
            "1486.05"
          ],
          [
            "2024-05-24",
            "1480.25"
          ],
          [
            "2024-05-27",
            "1466.25"
          ],
          [
            "2024-05-28",
            "1456.20"
          ],
          [
            "2024-05-29",
            "1440.78"
          ],
          [
            "2024-05-30",
            "1424.85"
          ],
          [
            "2024-05-31",
            "1430.40"
          ],
          [
            "2024-06-03",
            "1510.33"
          ],
          [
            "2024-06-04",
            "1397.28"
          ],
          [
            "2024-06-05",
            "1420.75"
          ],
          [
            "2024-06-06",
            "1431.60"
          ],
          [
            "2024-06-07",
            "1469.95"
          ],
          [
            "2024-06-10",
            "1471.40"
          ],
          [
            "2024-06-11",
            "1456.68"
          ],
          [
            "2024-06-12",
            "1463.33"
          ],
          [
            "2024-06-13",
            "1465.25"
          ],
          [
            "2024-06-14",
            "1477.55"
          ],
          [
            "2024-06-18",
            "1481.03"
          ],
          [
            "2024-06-19",
            "1458.65"
          ],
          [
            "2024-06-20",
            "1473.70"
          ],
          [
            "2024-06-21",
            "1454.20"
          ],
          [
            "2024-06-24",
            "1441.48"
          ],
          [
            "2024-06-25",
            "1454.15"
          ],
          [
            "2024-06-26",
            "1514.03"
          ],
          [
            "2024-06-27",
            "1530.55"
          ],
          [
            "2024-06-28",
            "1565.40"
          ],
          [
            "2024-07-01",
            "1560.15"
          ],
          [
            "2024-07-02",
            "1565.18"
          ],
          [
            "2024-07-03",
            "1552.43"
          ],
          [
            "2024-07-04",
            "1554.03"
          ],
          [
            "2024-07-05",
            "1588.63"
          ],
          [
            "2024-07-08",
            "1600.90"
          ],
          [
            "2024-07-09",
            "1590.28"
          ],
          [
            "2024-07-10",
            "1584.23"
          ],
          [
            "2024-07-11",
            "1580.65"
          ],
          [
            "2024-07-12",
            "1596.73"
          ],
          [
            "2024-07-15",
            "1597.23"
          ],
          [
            "2024-07-16",
            "1576.25"
          ],
          [
            "2024-07-18",
            "1586.68"
          ],
          [
            "2024-07-19",
            "1555.15"
          ],
          [
            "2024-07-22",
            "1500.68"
          ],
          [
            "2024-07-23",
            "1487.90"
          ],
          [
            "2024-07-24",
            "1495.70"
          ],
          [
            "2024-07-25",
            "1492.40"
          ],
          [
            "2024-07-26",
            "1509.03"
          ],
          [
            "2024-07-29",
            "1520.10"
          ],
          [
            "2024-07-30",
            "1513.15"
          ],
          [
            "2024-07-31",
            "1505.43"
          ],
          [
            "2024-08-01",
            "1515.30"
          ],
          [
            "2024-08-02",
            "1499.33"
          ],
          [
            "2024-08-05",
            "1447.33"
          ],
          [
            "2024-08-06",
            "1456.05"
          ],
          [
            "2024-08-07",
            "1464.83"
          ],
          [
            "2024-08-08",
            "1449.13"
          ],
          [
            "2024-08-09",
            "1474.30"
          ],
          [
            "2024-08-12",
            "1460.63"
          ],
          [
            "2024-08-13",
            "1463.63"
          ],
          [
            "2024-08-14",
            "1461.85"
          ],
          [
            "2024-08-16",
            "1478.20"
          ],
          [
            "2024-08-19",
            "1488.40"
          ],
          [
            "2024-08-20",
            "1495.95"
          ],
          [
            "2024-08-21",
            "1498.68"
          ],
          [
            "2024-08-22",
            "1498.13"
          ],
          [
            "2024-08-23",
            "1499.98"
          ],
          [
            "2024-08-26",
            "1512.60"
          ],
          [
            "2024-08-27",
            "1500.45"
          ],
          [
            "2024-08-28",
            "1498.30"
          ],
          [
            "2024-08-29",
            "1520.93"
          ],
          [
            "2024-08-30",
            "1509.63"
          ],
          [
            "2024-09-02",
            "1516.25"
          ],
          [
            "2024-09-03",
            "1509.13"
          ],
          [
            "2024-09-04",
            "1514.55"
          ],
          [
            "2024-09-05",
            "1492.98"
          ],
          [
            "2024-09-06",
            "1464.83"
          ],
          [
            "2024-09-09",
            "1462.45"
          ],
          [
            "2024-09-10",
            "1461.53"
          ],
          [
            "2024-09-11",
            "1451.50"
          ],
          [
            "2024-09-12",
            "1479.80"
          ],
          [
            "2024-09-13",
            "1472.63"
          ],
          [
            "2024-09-16",
            "1471.35"
          ],
          [
            "2024-09-17",
            "1472.30"
          ],
          [
            "2024-09-18",
            "1463.45"
          ],
          [
            "2024-09-19",
            "1469.68"
          ],
          [
            "2024-09-20",
            "1485.93"
          ],
          [
            "2024-09-23",
            "1493.38"
          ],
          [
            "2024-09-24",
            "1489.38"
          ],
          [
            "2024-09-25",
            "1493.95"
          ],
          [
            "2024-09-26",
            "1497.95"
          ],
          [
            "2024-09-27",
            "1526.18"
          ],
          [
            "2024-09-30",
            "1476.58"
          ],
          [
            "2024-10-01",
            "1464.83"
          ],
          [
            "2024-10-03",
            "1406.98"
          ],
          [
            "2024-10-04",
            "1386.53"
          ],
          [
            "2024-10-07",
            "1370.73"
          ],
          [
            "2024-10-08",
            "1397.35"
          ],
          [
            "2024-10-09",
            "1374.60"
          ],
          [
            "2024-10-10",
            "1371.05"
          ],
          [
            "2024-10-11",
            "1372.10"
          ],
          [
            "2024-10-14",
            "1372.53"
          ],
          [
            "2024-10-15",
            "1344.03"
          ],
          [
            "2024-10-16",
            "1354.08"
          ],
          [
            "2024-10-17",
            "1356.43"
          ],
          [
            "2024-10-18",
            "1359.30"
          ],
          [
            "2024-10-21",
            "1369.20"
          ],
          [
            "2024-10-22",
            "1343.35"
          ],
          [
            "2024-10-23",
            "1338.53"
          ],
          [
            "2024-10-24",
            "1339.80"
          ],
          [
            "2024-10-25",
            "1327.85"
          ],
          [
            "2024-10-28",
            "1334.35"
          ],
          [
            "2024-10-29",
            "1340.00"
          ],
          [
            "2024-10-30",
            "1343.90"
          ],
          [
            "2024-10-31",
            "1332.05"
          ],
          [
            "2024-11-01",
            "1338.65"
          ]
        ],
        "meta": {
          "is_weekly": false
        }
      },
      {
        "metric": "DMA50",
        "label": "50 DMA",
        "values": [
          [
            "2024-05-07",
            "1447.41"
          ],
          [
            "2024-05-08",
            "1446.28"
          ],
          [
            "2024-05-09",
            "1444.23"
          ],
          [
            "2024-05-10",
            "1442.79"
          ],
          [
            "2024-05-13",
            "1441.22"
          ],
          [
            "2024-05-14",
            "1440.39"
          ],
          [
            "2024-05-15",
            "1439.44"
          ],
          [
            "2024-05-16",
            "1438.89"
          ],
          [
            "2024-05-17",
            "1438.77"
          ],
          [
            "2024-05-18",
            "1438.61"
          ],
          [
            "2024-05-21",
            "1438.51"
          ],
          [
            "2024-05-22",
            "1439.38"
          ],
          [
            "2024-05-23",
            "1441.21"
          ],
          [
            "2024-05-24",
            "1442.74"
          ],
          [
            "2024-05-27",
            "1443.66"
          ],
          [
            "2024-05-28",
            "1444.16"
          ],
          [
            "2024-05-29",
            "1444.02"
          ],
          [
            "2024-05-30",
            "1443.27"
          ],
          [
            "2024-05-31",
            "1442.77"
          ],
          [
            "2024-06-03",
            "1445.42"
          ],
          [
            "2024-06-04",
            "1443.53"
          ],
          [
            "2024-06-05",
            "1442.64"
          ],
          [
            "2024-06-06",
            "1442.20"
          ],
          [
            "2024-06-07",
            "1443.29"
          ],
          [
            "2024-06-10",
            "1444.39"
          ],
          [
            "2024-06-11",
            "1444.88"
          ],
          [
            "2024-06-12",
            "1445.60"
          ],
          [
            "2024-06-13",
            "1446.37"
          ],
          [
            "2024-06-14",
            "1447.59"
          ],
          [
            "2024-06-18",
            "1448.90"
          ],
          [
            "2024-06-19",
            "1449.29"
          ],
          [
            "2024-06-20",
            "1450.24"
          ],
          [
            "2024-06-21",
            "1450.40"
          ],
          [
            "2024-06-24",
            "1450.05"
          ],
          [
            "2024-06-25",
            "1450.21"
          ],
          [
            "2024-06-26",
            "1452.71"
          ],
          [
            "2024-06-27",
            "1455.76"
          ],
          [
            "2024-06-28",
            "1460.06"
          ],
          [
            "2024-07-01",
            "1463.99"
          ],
          [
            "2024-07-02",
            "1467.96"
          ],
          [
            "2024-07-03",
            "1471.27"
          ],
          [
            "2024-07-04",
            "1474.52"
          ],
          [
            "2024-07-05",
            "1478.99"
          ],
          [
            "2024-07-08",
            "1483.77"
          ],
          [
            "2024-07-09",
            "1487.95"
          ],
          [
            "2024-07-10",
            "1491.72"
          ],
          [
            "2024-07-11",
            "1495.21"
          ],
          [
            "2024-07-12",
            "1499.19"
          ],
          [
            "2024-07-15",
            "1503.04"
          ],
          [
            "2024-07-16",
            "1505.91"
          ],
          [
            "2024-07-18",
            "1509.08"
          ],
          [
            "2024-07-19",
            "1510.88"
          ],
          [
            "2024-07-22",
            "1510.48"
          ],
          [
            "2024-07-23",
            "1509.60"
          ],
          [
            "2024-07-24",
            "1509.05"
          ],
          [
            "2024-07-25",
            "1508.40"
          ],
          [
            "2024-07-26",
            "1508.42"
          ],
          [
            "2024-07-29",
            "1508.88"
          ],
          [
            "2024-07-30",
            "1509.05"
          ],
          [
            "2024-07-31",
            "1508.91"
          ],
          [
            "2024-08-01",
            "1509.16"
          ],
          [
            "2024-08-02",
            "1508.77"
          ],
          [
            "2024-08-05",
            "1506.36"
          ],
          [
            "2024-08-06",
            "1504.39"
          ],
          [
            "2024-08-07",
            "1502.84"
          ],
          [
            "2024-08-08",
            "1500.73"
          ],
          [
            "2024-08-09",
            "1499.70"
          ],
          [
            "2024-08-12",
            "1498.16"
          ],
          [
            "2024-08-13",
            "1496.81"
          ],
          [
            "2024-08-14",
            "1495.44"
          ],
          [
            "2024-08-16",
            "1494.76"
          ],
          [
            "2024-08-19",
            "1494.51"
          ],
          [
            "2024-08-20",
            "1494.57"
          ],
          [
            "2024-08-21",
            "1494.73"
          ],
          [
            "2024-08-22",
            "1494.86"
          ],
          [
            "2024-08-23",
            "1495.06"
          ],
          [
            "2024-08-26",
            "1495.75"
          ],
          [
            "2024-08-27",
            "1495.94"
          ],
          [
            "2024-08-28",
            "1496.03"
          ],
          [
            "2024-08-29",
            "1497.01"
          ],
          [
            "2024-08-30",
            "1497.50"
          ],
          [
            "2024-09-02",
            "1498.24"
          ],
          [
            "2024-09-03",
            "1498.66"
          ],
          [
            "2024-09-04",
            "1499.29"
          ],
          [
            "2024-09-05",
            "1499.04"
          ],
          [
            "2024-09-06",
            "1497.70"
          ],
          [
            "2024-09-09",
            "1496.32"
          ],
          [
            "2024-09-10",
            "1494.95"
          ],
          [
            "2024-09-11",
            "1493.25"
          ],
          [
            "2024-09-12",
            "1492.72"
          ],
          [
            "2024-09-13",
            "1491.93"
          ],
          [
            "2024-09-16",
            "1491.12"
          ],
          [
            "2024-09-17",
            "1490.39"
          ],
          [
            "2024-09-18",
            "1489.33"
          ],
          [
            "2024-09-19",
            "1488.56"
          ],
          [
            "2024-09-20",
            "1488.46"
          ],
          [
            "2024-09-23",
            "1488.65"
          ],
          [
            "2024-09-24",
            "1488.68"
          ],
          [
            "2024-09-25",
            "1488.88"
          ],
          [
            "2024-09-26",
            "1489.24"
          ],
          [
            "2024-09-27",
            "1490.69"
          ],
          [
            "2024-09-30",
            "1490.14"
          ],
          [
            "2024-10-01",
            "1489.14"
          ],
          [
            "2024-10-03",
            "1485.92"
          ],
          [
            "2024-10-04",
            "1482.02"
          ],
          [
            "2024-10-07",
            "1477.66"
          ],
          [
            "2024-10-08",
            "1474.51"
          ],
          [
            "2024-10-09",
            "1470.59"
          ],
          [
            "2024-10-10",
            "1466.69"
          ],
          [
            "2024-10-11",
            "1462.98"
          ],
          [
            "2024-10-14",
            "1459.43"
          ],
          [
            "2024-10-15",
            "1454.91"
          ],
          [
            "2024-10-16",
            "1450.95"
          ],
          [
            "2024-10-17",
            "1447.25"
          ],
          [
            "2024-10-18",
            "1443.80"
          ],
          [
            "2024-10-21",
            "1440.87"
          ],
          [
            "2024-10-22",
            "1437.05"
          ],
          [
            "2024-10-23",
            "1433.18"
          ],
          [
            "2024-10-24",
            "1429.52"
          ],
          [
            "2024-10-25",
            "1425.53"
          ],
          [
            "2024-10-28",
            "1421.96"
          ],
          [
            "2024-10-29",
            "1418.74"
          ],
          [
            "2024-10-30",
            "1415.81"
          ],
          [
            "2024-10-31",
            "1412.52"
          ],
          [
            "2024-11-01",
            "1409.63"
          ]
        ],
        "meta": {}
      },
      {
        "metric": "DMA200",
        "label": "200 DMA",
        "values": [
          [
            "2024-05-07",
            "1334.21"
          ],
          [
            "2024-05-08",
            "1335.05"
          ],
          [
            "2024-05-09",
            "1335.64"
          ],
          [
            "2024-05-10",
            "1336.35"
          ],
          [
            "2024-05-13",
            "1337.01"
          ],
          [
            "2024-05-14",
            "1337.84"
          ],
          [
            "2024-05-15",
            "1338.62"
          ],
          [
            "2024-05-16",
            "1339.48"
          ],
          [
            "2024-05-17",
            "1340.44"
          ],
          [
            "2024-05-18",
            "1341.38"
          ],
          [
            "2024-05-21",
            "1342.32"
          ],
          [
            "2024-05-22",
            "1343.50"
          ],
          [
            "2024-05-23",
            "1344.92"
          ],
          [
            "2024-05-24",
            "1346.27"
          ],
          [
            "2024-05-27",
            "1347.46"
          ],
          [
            "2024-05-28",
            "1348.54"
          ],
          [
            "2024-05-29",
            "1349.46"
          ],
          [
            "2024-05-30",
            "1350.21"
          ],
          [
            "2024-05-31",
            "1351.01"
          ],
          [
            "2024-06-03",
            "1352.59"
          ],
          [
            "2024-06-04",
            "1353.04"
          ],
          [
            "2024-06-05",
            "1353.71"
          ],
          [
            "2024-06-06",
            "1354.49"
          ],
          [
            "2024-06-07",
            "1355.64"
          ],
          [
            "2024-06-10",
            "1356.79"
          ],
          [
            "2024-06-11",
            "1357.78"
          ],
          [
            "2024-06-12",
            "1358.83"
          ],
          [
            "2024-06-13",
            "1359.89"
          ],
          [
            "2024-06-14",
            "1361.06"
          ],
          [
            "2024-06-18",
            "1362.26"
          ],
          [
            "2024-06-19",
            "1363.21"
          ],
          [
            "2024-06-20",
            "1364.31"
          ],
          [
            "2024-06-21",
            "1365.21"
          ],
          [
            "2024-06-24",
            "1365.97"
          ],
          [
            "2024-06-25",
            "1366.84"
          ],
          [
            "2024-06-26",
            "1368.31"
          ],
          [
            "2024-06-27",
            "1369.92"
          ],
          [
            "2024-06-28",
            "1371.87"
          ],
          [
            "2024-07-01",
            "1373.74"
          ],
          [
            "2024-07-02",
            "1375.65"
          ],
          [
            "2024-07-03",
            "1377.41"
          ],
          [
            "2024-07-04",
            "1379.16"
          ],
          [
            "2024-07-05",
            "1381.25"
          ],
          [
            "2024-07-08",
            "1383.43"
          ],
          [
            "2024-07-09",
            "1385.49"
          ],
          [
            "2024-07-10",
            "1387.47"
          ],
          [
            "2024-07-11",
            "1389.39"
          ],
          [
            "2024-07-12",
            "1391.45"
          ],
          [
            "2024-07-15",
            "1393.50"
          ],
          [
            "2024-07-16",
            "1395.32"
          ],
          [
            "2024-07-18",
            "1397.22"
          ],
          [
            "2024-07-19",
            "1398.80"
          ],
          [
            "2024-07-22",
            "1399.81"
          ],
          [
            "2024-07-23",
            "1400.69"
          ],
          [
            "2024-07-24",
            "1401.63"
          ],
          [
            "2024-07-25",
            "1402.53"
          ],
          [
            "2024-07-26",
            "1403.59"
          ],
          [
            "2024-07-29",
            "1404.75"
          ],
          [
            "2024-07-30",
            "1405.83"
          ],
          [
            "2024-07-31",
            "1406.82"
          ],
          [
            "2024-08-01",
            "1407.90"
          ],
          [
            "2024-08-02",
            "1408.81"
          ],
          [
            "2024-08-05",
            "1409.20"
          ],
          [
            "2024-08-06",
            "1409.66"
          ],
          [
            "2024-08-07",
            "1410.21"
          ],
          [
            "2024-08-08",
            "1410.60"
          ],
          [
            "2024-08-09",
            "1411.23"
          ],
          [
            "2024-08-12",
            "1411.72"
          ],
          [
            "2024-08-13",
            "1412.24"
          ],
          [
            "2024-08-14",
            "1412.73"
          ],
          [
            "2024-08-16",
            "1413.38"
          ],
          [
            "2024-08-19",
            "1414.13"
          ],
          [
            "2024-08-20",
            "1414.95"
          ],
          [
            "2024-08-21",
            "1415.78"
          ],
          [
            "2024-08-22",
            "1416.60"
          ],
          [
            "2024-08-23",
            "1417.43"
          ],
          [
            "2024-08-26",
            "1418.37"
          ],
          [
            "2024-08-27",
            "1419.19"
          ],
          [
            "2024-08-28",
            "1419.98"
          ],
          [
            "2024-08-29",
            "1420.98"
          ],
          [
            "2024-08-30",
            "1421.86"
          ],
          [
            "2024-09-02",
            "1422.80"
          ],
          [
            "2024-09-03",
            "1423.66"
          ],
          [
            "2024-09-04",
            "1424.57"
          ],
          [
            "2024-09-05",
            "1425.25"
          ],
          [
            "2024-09-06",
            "1425.64"
          ],
          [
            "2024-09-09",
            "1426.01"
          ],
          [
            "2024-09-10",
            "1426.36"
          ],
          [
            "2024-09-11",
            "1426.61"
          ],
          [
            "2024-09-12",
            "1427.14"
          ],
          [
            "2024-09-13",
            "1427.59"
          ],
          [
            "2024-09-16",
            "1428.03"
          ],
          [
            "2024-09-17",
            "1428.47"
          ],
          [
            "2024-09-18",
            "1428.82"
          ],
          [
            "2024-09-19",
            "1429.22"
          ],
          [
            "2024-09-20",
            "1429.79"
          ],
          [
            "2024-09-23",
            "1430.42"
          ],
          [
            "2024-09-24",
            "1431.01"
          ],
          [
            "2024-09-25",
            "1431.63"
          ],
          [
            "2024-09-26",
            "1432.29"
          ],
          [
            "2024-09-27",
            "1433.23"
          ],
          [
            "2024-09-30",
            "1433.66"
          ],
          [
            "2024-10-01",
            "1433.97"
          ],
          [
            "2024-10-03",
            "1433.70"
          ],
          [
            "2024-10-04",
            "1433.23"
          ],
          [
            "2024-10-07",
            "1432.61"
          ],
          [
            "2024-10-08",
            "1432.26"
          ],
          [
            "2024-10-09",
            "1431.69"
          ],
          [
            "2024-10-10",
            "1431.08"
          ],
          [
            "2024-10-11",
            "1430.49"
          ],
          [
            "2024-10-14",
            "1429.92"
          ],
          [
            "2024-10-15",
            "1429.06"
          ],
          [
            "2024-10-16",
            "1428.32"
          ],
          [
            "2024-10-17",
            "1427.60"
          ],
          [
            "2024-10-18",
            "1426.92"
          ],
          [
            "2024-10-21",
            "1426.35"
          ],
          [
            "2024-10-22",
            "1425.52"
          ],
          [
            "2024-10-23",
            "1424.66"
          ],
          [
            "2024-10-24",
            "1423.81"
          ],
          [
            "2024-10-25",
            "1422.86"
          ],
          [
            "2024-10-28",
            "1421.98"
          ],
          [
            "2024-10-29",
            "1421.16"
          ],
          [
            "2024-10-30",
            "1420.39"
          ],
          [
            "2024-10-31",
            "1419.51"
          ],
          [
            "2024-11-01",
            "1418.71"
          ]
        ],
        "meta": {}
      },
      {
        "metric": "Volume",
        "label": "Volume",
        "values": [
          [
            "2024-05-07",
            14856560,
            {
              "delivery": 72
            }
          ],
          [
            "2024-05-08",
            9996739,
            {
              "delivery": 51
            }
          ],
          [
            "2024-05-09",
            11222267,
            {
              "delivery": 51
            }
          ],
          [
            "2024-05-10",
            11270633,
            {
              "delivery": 47
            }
          ],
          [
            "2024-05-13",
            8719754,
            {
              "delivery": 64
            }
          ],
          [
            "2024-05-14",
            7463509,
            {
              "delivery": 60
            }
          ],
          [
            "2024-05-15",
            5150161,
            {
              "delivery": 51
            }
          ],
          [
            "2024-05-16",
            14854016,
            {
              "delivery": 45
            }
          ],
          [
            "2024-05-17",
            11747190,
            {
              "delivery": 49
            }
          ],
          [
            "2024-05-18",
            444667,
            {
              "delivery": 42
            }
          ],
          [
            "2024-05-21",
            11437904,
            {
              "delivery": 70
            }
          ],
          [
            "2024-05-22",
            10503288,
            {
              "delivery": 47
            }
          ],
          [
            "2024-05-23",
            15002372,
            {
              "delivery": 52
            }
          ],
          [
            "2024-05-24",
            8007970,
            {
              "delivery": 52
            }
          ],
          [
            "2024-05-27",
            6898152,
            {
              "delivery": 61
            }
          ],
          [
            "2024-05-28",
            8052802,
            {
              "delivery": 65
            }
          ],
          [
            "2024-05-29",
            7513693,
            {
              "delivery": 62
            }
          ],
          [
            "2024-05-30",
            13315122,
            {
              "delivery": 66
            }
          ],
          [
            "2024-05-31",
            32664404,
            {
              "delivery": 57
            }
          ],
          [
            "2024-06-03",
            22158331,
            {
              "delivery": 57
            }
          ],
          [
            "2024-06-04",
            38779823,
            {
              "delivery": 53
            }
          ],
          [
            "2024-06-05",
            17825061,
            {
              "delivery": 58
            }
          ],
          [
            "2024-06-06",
            18366477,
            {
              "delivery": 38
            }
          ],
          [
            "2024-06-07",
            20463100,
            {
              "delivery": 46
            }
          ],
          [
            "2024-06-10",
            9425892,
            {
              "delivery": 50
            }
          ],
          [
            "2024-06-11",
            12026798,
            {
              "delivery": 67
            }
          ],
          [
            "2024-06-12",
            10426855,
            {
              "delivery": 52
            }
          ],
          [
            "2024-06-13",
            9296286,
            {
              "delivery": 52
            }
          ],
          [
            "2024-06-14",
            8530687,
            {
              "delivery": 53
            }
          ],
          [
            "2024-06-18",
            7412786,
            {
              "delivery": 59
            }
          ],
          [
            "2024-06-19",
            8920890,
            {
              "delivery": 54
            }
          ],
          [
            "2024-06-20",
            16312020,
            {
              "delivery": 44
            }
          ],
          [
            "2024-06-21",
            32965300,
            {
              "delivery": 50
            }
          ],
          [
            "2024-06-24",
            9003742,
            {
              "delivery": 63
            }
          ],
          [
            "2024-06-25",
            7464838,
            {
              "delivery": 62
            }
          ],
          [
            "2024-06-26",
            23460202,
            {
              "delivery": 52
            }
          ],
          [
            "2024-06-27",
            29045250,
            {
              "delivery": null
            }
          ],
          [
            "2024-06-28",
            31023112,
            {
              "delivery": 44
            }
          ],
          [
            "2024-07-01",
            8099517,
            {
              "delivery": 53
            }
          ],
          [
            "2024-07-02",
            8063438,
            {
              "delivery": 50
            }
          ],
          [
            "2024-07-03",
            9392959,
            {
              "delivery": 53
            }
          ],
          [
            "2024-07-04",
            7102117,
            {
              "delivery": 42
            }
          ],
          [
            "2024-07-05",
            13787622,
            {
              "delivery": 34
            }
          ],
          [
            "2024-07-08",
            9884546,
            {
              "delivery": 61
            }
          ],
          [
            "2024-07-09",
            7295092,
            {
              "delivery": 63
            }
          ],
          [
            "2024-07-10",
            7276372,
            {
              "delivery": 48
            }
          ],
          [
            "2024-07-11",
            12147310,
            {
              "delivery": 42
            }
          ],
          [
            "2024-07-12",
            14369314,
            {
              "delivery": 40
            }
          ],
          [
            "2024-07-15",
            5648164,
            {
              "delivery": 52
            }
          ],
          [
            "2024-07-16",
            9505150,
            {
              "delivery": 65
            }
          ],
          [
            "2024-07-18",
            12610836,
            {
              "delivery": 47
            }
          ],
          [
            "2024-07-19",
            14288100,
            {
              "delivery": 57
            }
          ],
          [
            "2024-07-22",
            20054457,
            {
              "delivery": 60
            }
          ],
          [
            "2024-07-23",
            18579771,
            {
              "delivery": 56
            }
          ],
          [
            "2024-07-24",
            12241839,
            {
              "delivery": 45
            }
          ],
          [
            "2024-07-25",
            12694620,
            {
              "delivery": 59
            }
          ],
          [
            "2024-07-26",
            11402231,
            {
              "delivery": 44
            }
          ],
          [
            "2024-07-29",
            7657606,
            {
              "delivery": 58
            }
          ],
          [
            "2024-07-30",
            8519690,
            {
              "delivery": 58
            }
          ],
          [
            "2024-07-31",
            10182641,
            {
              "delivery": 63
            }
          ],
          [
            "2024-08-01",
            10903954,
            {
              "delivery": 67
            }
          ],
          [
            "2024-08-02",
            11493134,
            {
              "delivery": 57
            }
          ],
          [
            "2024-08-05",
            17540974,
            {
              "delivery": 64
            }
          ],
          [
            "2024-08-06",
            10624456,
            {
              "delivery": 51
            }
          ],
          [
            "2024-08-07",
            8419087,
            {
              "delivery": 63
            }
          ],
          [
            "2024-08-08",
            12741574,
            {
              "delivery": 60
            }
          ],
          [
            "2024-08-09",
            7012288,
            {
              "delivery": 43
            }
          ],
          [
            "2024-08-12",
            8477504,
            {
              "delivery": 64
            }
          ],
          [
            "2024-08-13",
            9237059,
            {
              "delivery": 68
            }
          ],
          [
            "2024-08-14",
            6381640,
            {
              "delivery": 55
            }
          ],
          [
            "2024-08-16",
            10663408,
            {
              "delivery": 42
            }
          ],
          [
            "2024-08-19",
            13982382,
            {
              "delivery": 66
            }
          ],
          [
            "2024-08-20",
            9035406,
            {
              "delivery": 66
            }
          ],
          [
            "2024-08-21",
            7950693,
            {
              "delivery": 64
            }
          ],
          [
            "2024-08-22",
            10832712,
            {
              "delivery": 62
            }
          ],
          [
            "2024-08-23",
            10300613,
            {
              "delivery": 48
            }
          ],
          [
            "2024-08-26",
            9206293,
            {
              "delivery": 61
            }
          ],
          [
            "2024-08-27",
            6396276,
            {
              "delivery": 64
            }
          ],
          [
            "2024-08-28",
            9712482,
            {
              "delivery": 72
            }
          ],
          [
            "2024-08-29",
            39426839,
            {
              "delivery": 41
            }
          ],
          [
            "2024-08-30",
            43012397,
            {
              "delivery": 56
            }
          ],
          [
            "2024-09-02",
            11841060,
            {
              "delivery": 60
            }
          ],
          [
            "2024-09-03",
            11337518,
            {
              "delivery": 72
            }
          ],
          [
            "2024-09-04",
            12385874,
            {
              "delivery": 70
            }
          ],
          [
            "2024-09-05",
            16888618,
            {
              "delivery": 60
            }
          ],
          [
            "2024-09-06",
            21434240,
            {
              "delivery": 54
            }
          ],
          [
            "2024-09-09",
            9446363,
            {
              "delivery": 63
            }
          ],
          [
            "2024-09-10",
            11203270,
            {
              "delivery": 61
            }
          ],
          [
            "2024-09-11",
            10017552,
            {
              "delivery": 61
            }
          ],
          [
            "2024-09-12",
            23039210,
            {
              "delivery": 50
            }
          ],
          [
            "2024-09-13",
            9436367,
            {
              "delivery": 49
            }
          ],
          [
            "2024-09-16",
            5564614,
            {
              "delivery": 60
            }
          ],
          [
            "2024-09-17",
            6295972,
            {
              "delivery": 66
            }
          ],
          [
            "2024-09-18",
            8969403,
            {
              "delivery": 62
            }
          ],
          [
            "2024-09-19",
            17077457,
            {
              "delivery": 60
            }
          ],
          [
            "2024-09-20",
            34303283,
            {
              "delivery": 54
            }
          ],
          [
            "2024-09-23",
            9429277,
            {
              "delivery": 62
            }
          ],
          [
            "2024-09-24",
            17600996,
            {
              "delivery": 80
            }
          ],
          [
            "2024-09-25",
            7363798,
            {
              "delivery": 61
            }
          ],
          [
            "2024-09-26",
            19122050,
            {
              "delivery": 67
            }
          ],
          [
            "2024-09-27",
            21499729,
            {
              "delivery": 68
            }
          ],
          [
            "2024-09-30",
            27859469,
            {
              "delivery": 72
            }
          ],
          [
            "2024-10-01",
            17900006,
            {
              "delivery": 72
            }
          ],
          [
            "2024-10-03",
            44846897,
            {
              "delivery": 70
            }
          ],
          [
            "2024-10-04",
            39312977,
            {
              "delivery": 58
            }
          ],
          [
            "2024-10-07",
            23303658,
            {
              "delivery": 70
            }
          ],
          [
            "2024-10-08",
            17339309,
            {
              "delivery": 61
            }
          ],
          [
            "2024-10-09",
            14951694,
            {
              "delivery": 64
            }
          ],
          [
            "2024-10-10",
            23089544,
            {
              "delivery": 72
            }
          ],
          [
            "2024-10-11",
            8862686,
            {
              "delivery": 44
            }
          ],
          [
            "2024-10-14",
            12726226,
            {
              "delivery": 67
            }
          ],
          [
            "2024-10-15",
            35049817,
            {
              "delivery": 66
            }
          ],
          [
            "2024-10-16",
            20669637,
            {
              "delivery": 62
            }
          ],
          [
            "2024-10-17",
            17205969,
            {
              "delivery": 65
            }
          ],
          [
            "2024-10-18",
            13501919,
            {
              "delivery": 52
            }
          ],
          [
            "2024-10-21",
            14461427,
            {
              "delivery": 66
            }
          ],
          [
            "2024-10-22",
            16210236,
            {
              "delivery": 64
            }
          ],
          [
            "2024-10-23",
            18064292,
            {
              "delivery": 63
            }
          ],
          [
            "2024-10-24",
            23023321,
            {
              "delivery": 65
            }
          ],
          [
            "2024-10-25",
            20067987,
            {
              "delivery": 63
            }
          ],
          [
            "2024-10-28",
            11208484,
            {
              "delivery": 60
            }
          ],
          [
            "2024-10-29",
            12371734,
            {
              "delivery": 65
            }
          ],
          [
            "2024-10-30",
            12308744,
            {
              "delivery": 62
            }
          ],
          [
            "2024-10-31",
            9989607,
            {
              "delivery": 63
            }
          ],
          [
            "2024-11-01",
            2418194,
            {
              "delivery": 71
            }
          ]
        ],
        "meta": {}
      }
    ]
  }

  useEffect(() => {
    if (!apiResponse)
      return


    const extractCompanyAndMarketData = (basicInfo: any) => {
      // Extracting company information
      const {
        ISIN_NUMBER,
        Industry: industry,
        fld_cin: CIN,
        lISTING_DATE: listingDate,
        sEmail,
        sURL
      } = basicInfo;

      // Extracting market data from market_depth and price
      const {
        market_depth: {
          Fifty2WkHigh_adj: fiftyTwoWeekHigh,
          Fifty2WkLow_adj: fiftyTwoWeekLow,
          MonthHighLow: monthHighLow,
          WeekHighLow: weekHighLow,
        },
        price: {
          LTP: lastPrice,
          Chg: change,
          PcChg: pChange,
        }
      } = basicInfo;

      // Define the structured output
      return {
        basicInfo: {
          isin: ISIN_NUMBER,
          industry,
          CIN,
          listingDate: listingDate.split('T')[0],
          email: sEmail,
          website: sURL
        },
        marketData: {
          lastPrice,
          change,
          pChange,
          priceRanges: {
            fiftyTwoWeekHigh,
            fiftyTwoWeekLow,
            monthHighLow,
            weekHighLow
          }
        }
      };
    };

    const data = extractCompanyAndMarketData(apiResponse?.basic_info);
    setBasicInfo(data.basicInfo);
    setMarketData(data.marketData);
    setTableData(apiResponse?.table_data);
    setReportsUrls(apiResponse?.reports_urls);

  }, [apiResponse]);

  useEffect(() => {
    if (selectedTicker) {
      setApiResponse(null);
      const fetchStockInfo = async () => {
        try {
          const isLocalhost = Boolean(
            window.location.hostname === "localhost" ||
            // [::1] is the IPv6 localhost address.
            window.location.hostname === "[::1]" ||
            // 127.0.0.0/8 are considered localhost for IPv4.
            window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
          );

          const API_URL = isLocalhost ? "http://127.0.0.1:5000" : "https://stock-ai-backend.onrender.com";

          try {
            // Fetch basic stock info
            const basicResponse = await fetch(`${API_URL}/show_basic_stock_info/${selectedTicker}`);
            if (!basicResponse.ok) {
              throw new Error("Failed to fetch basic stock info");
            }
            const basicData = await basicResponse.json();

            // Fetch results table stock info
            const resultsTableResponse = await fetch(`${API_URL}/show_results_table_stock_info/${selectedTicker}`);
            if (!resultsTableResponse.ok) {
              throw new Error("Failed to fetch results table stock info");
            }
            const resultsTableData = await resultsTableResponse.json();

            // Fetch reports URLs stock info
            const reportsResponse = await fetch(`${API_URL}/show_reports_urls_stock_info/${selectedTicker}`);
            if (!reportsResponse.ok) {
              throw new Error("Failed to fetch reports URLs stock info");
            }
            const reportsData = await reportsResponse.json();

            // Fetch latest announcements
            const announcementsResponse = await fetch(`${API_URL}/show_latest_annoucments/${selectedTicker}`);
            if (!announcementsResponse.ok) {
              throw new Error("Failed to fetch latest announcements");
            }
            const announcementsData = await announcementsResponse.json();

            // Fetch corporate actions
            const corpActionsResponse = await fetch(`${API_URL}/show_corp_actions/${selectedTicker}`);
            if (!corpActionsResponse.ok) {
              throw new Error("Failed to fetch corporate actions");
            }
            const corpActionsData = await corpActionsResponse.json();

            // Fetch shareholding pattern
            const shareholdingResponse = await fetch(`${API_URL}/show_shareholding_pattern/${selectedTicker}`);
            if (!shareholdingResponse.ok) {
              throw new Error("Failed to fetch shareholding pattern");
            }
            const shareholdingData = await shareholdingResponse.json();

            // Fetch peer group information
            const peerGroupResponse = await fetch(`${API_URL}/show_peer_group/${selectedTicker}`);
            if (!peerGroupResponse.ok) {
              throw new Error("Failed to fetch peer group information");
            }
            const peerGroupData = await peerGroupResponse.json();

            // Merge all data into a single object
            const finalData = {
              ...basicData,
              ...resultsTableData,
              ...reportsData,
            };

            setCorporateActions(corpActionsData?.crop_actions);
            setPeerGroups(peerGroupData?.peer_group);
            setLatestAnnouncements(announcementsData?.latest_annoucments
            );
            setShareHoldingData(shareholdingData?.shareholding_pattern);


            // Set API response state
            setApiResponse(finalData);

          } catch (error) {
            console.error("Error fetching stock information:", error);
          }


        } catch (error) {
          console.error("There was a problem with the fetch operation:", error);
        }
      };

      fetchStockInfo();
    }
  }, [selectedTicker]); // Run effect when selectedTicker changes

  return (
    <>
      <AppBar position="static" sx={{ marginBottom: 2 }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          {/* Logo on the left */}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            StockAI
          </Typography>

          {/* Dropdown on the right */}
          <Box>
            <FormControl variant="outlined" size="small">
              <Select
                value={selectedTicker}
                onChange={handleTickerChange}
                displayEmpty
                sx={{ color: "white", backgroundColor: "primary.main" }}>
                <MenuItem value="" disabled>
                  Select a Ticker
                </MenuItem>
                {TICKER_LIST.map((ticker) => (
                  <MenuItem key={ticker} value={ticker}>
                    {ticker}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={{ flexGrow: 1, p: 3, mb: 10 }}>

        {selectedTicker ?
          <>
            {apiResponse ?
              <Grid container spacing={3}>
                {/* AI Card */}
                <Grid item xs={12} md={12}>
                  <StyledCard>
                    <CardHeader
                      title="AI Overview"
                      titleTypographyProps={{ variant: 'h6' }}
                    />
                    <CardContent>
                      <>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                          <MarkdownViewer />
                        </Box>
                      </>

                    </CardContent>
                  </StyledCard>
                </Grid>

                {/* Basic Info Card */}
                <Grid item xs={12} md={6}>
                  <StyledCard>
                    <CardHeader title="Company Overview" titleTypographyProps={{ variant: 'h6' }} />
                    <CardContent>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        {basicInfo && Object.entries(basicInfo).map(([key, value]) => (
                          <Box key={key} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                              {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}:
                            </Typography>
                            <Typography>{value}</Typography>
                          </Box>
                        ))}
                      </Box>
                    </CardContent>
                  </StyledCard>
                </Grid>

                {/* Market Data Card */}
                <Grid item xs={12} md={6}>
                  <StyledCard>
                    <CardHeader title="Market Overview" titleTypographyProps={{ variant: 'h6' }} />
                    <CardContent>
                      {marketData && (
                        <>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                            <Typography variant="h4">
                              ₹{marketData.lastPrice}
                            </Typography>
                            <Typography
                              variant="h6"
                              color={marketData.pChange > 0 ? 'success.main' : 'error.main'}
                            >
                              {marketData.pChange > 0 ? '+' : ''}{marketData.pChange}%
                            </Typography>
                          </Box>
                          <Divider sx={{ my: 2 }} />
                          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                                52 Week High/Low:
                              </Typography>
                              <Typography>
                                {marketData.priceRanges.fiftyTwoWeekHigh} / {marketData.priceRanges.fiftyTwoWeekLow}
                              </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                                Monthly High/Low:
                              </Typography>
                              <Typography>{marketData.priceRanges.monthHighLow}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                                Weekly High/Low:
                              </Typography>
                              <Typography>{marketData.priceRanges.weekHighLow}</Typography>
                            </Box>
                          </Box>
                        </>
                      )}
                    </CardContent>
                  </StyledCard>
                </Grid>

                {/* Stock Price vs DMA Chart */}
                <Grid item xs={12} md={12}>
                  <StyledCard>
                    <CardHeader
                      title="Stock Price vs DMA "
                      titleTypographyProps={{ variant: 'h6' }}
                    />
                    <CardContent>
                      <Box sx={{ width: '100%' }}>
                        <StockChart data={stockData} />
                      </Box>
                    </CardContent>
                  </StyledCard>
                </Grid>

                {/* Annual Income Table Data Card */}
                <Grid item xs={12} md={6}>
                  <StyledCard>
                    <CardHeader
                      title="Annual Income Statement (in Cr.)"
                      titleTypographyProps={{ variant: 'h6' }}
                    />
                    <CardContent>
                      <div dangerouslySetInnerHTML={{ __html: `<table>${tableData?.AnninCr}</table>` }} className="custom-table" />
                    </CardContent>
                  </StyledCard>
                </Grid>

                {/* Quarterly Income Table Data Card */}
                <Grid item xs={12} md={6}>
                  <StyledCard>
                    <CardHeader
                      title="Quarterly Income Statement (in Cr.)"
                      titleTypographyProps={{ variant: 'h6' }}
                    />
                    <CardContent>
                      <div dangerouslySetInnerHTML={{ __html: `<table>${tableData?.QtlyinCr}</table>` }} className="custom-table" />
                    </CardContent>
                  </StyledCard>
                </Grid>


                {/* Peer Groups */}
                <Grid item xs={12} md={6}>
                  <StyledCard>
                    <CardHeader
                      title="Peer Groups"
                      titleTypographyProps={{ variant: 'h6' }}
                    />
                    <CardContent>
                      <TableContainer sx={{ maxHeight: 600 }}>
                        <Table stickyHeader aria-label="peer groups table">
                          {/* Table Header */}
                          <TableHead>
                            <TableRow>
                              <TableCell><Typography variant="subtitle2" fontWeight="bold">Company Name</Typography></TableCell>
                              <TableCell><Typography variant="subtitle2" fontWeight="bold">LTP (₹)</Typography></TableCell>
                              <TableCell><Typography variant="subtitle2" fontWeight="bold">PE Ratio</Typography></TableCell>
                              <TableCell><Typography variant="subtitle2" fontWeight="bold">EPS (₹)</Typography></TableCell>
                              <TableCell><Typography variant="subtitle2" fontWeight="bold">Revenue (₹)</Typography></TableCell>
                              <TableCell><Typography variant="subtitle2" fontWeight="bold">PAT (₹)</Typography></TableCell>
                              <TableCell><Typography variant="subtitle2" fontWeight="bold">52 Week High (₹)</Typography></TableCell>
                              <TableCell><Typography variant="subtitle2" fontWeight="bold">52 Week Low (₹)</Typography></TableCell>
                            </TableRow>
                          </TableHead>
                          {/* Table Body */}
                          <TableBody>
                            {peerGroups && peerGroups.map((peer, index) => (
                              <TableRow key={index}>
                                <TableCell>{peer.Name}</TableCell>
                                <TableCell>₹{peer.LTP.toFixed(2)}</TableCell>
                                <TableCell>{peer.PE}</TableCell>
                                <TableCell>₹{peer.EPS}</TableCell>
                                <TableCell>₹{peer.Revenue.toLocaleString()}</TableCell>
                                <TableCell>₹{peer.PAT.toLocaleString()}</TableCell>
                                <TableCell>
                                  ₹{peer.w52hi.toFixed(2)} <br />
                                  <Typography variant="caption">on {new Date(peer.w52hidt).toLocaleDateString('en-US')}</Typography>
                                </TableCell>
                                <TableCell>
                                  ₹{peer.w52lo.toFixed(2)} <br />
                                  <Typography variant="caption">on {new Date(peer.w52lodt).toLocaleDateString('en-US')}</Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </StyledCard>
                </Grid>

                {/* Corporate Actions */}
                <Grid item xs={12} md={6}>
                  <StyledCard>
                    <CardHeader
                      title="Corporate Actions"
                      titleTypographyProps={{ variant: 'h6' }}
                    />
                    <CardContent>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell><strong>Date</strong></TableCell>
                              <TableCell><strong>Title</strong></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {corporateActions && corporateActions.map((action, index) => (
                              <TableRow key={index}>
                                <TableCell>{action?.date}</TableCell>
                                <TableCell>{action?.title}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </StyledCard>
                </Grid>

                {/* Shareholding Pattern */}
                <Grid item xs={12} md={6}>
                  <StyledCard>
                    <CardHeader
                      title="Shareholding Pattern"
                      titleTypographyProps={{ variant: 'h6' }}
                    />
                    <CardContent>
                      {/* Render HTML content directly */}
                      {shareholdingData && <Box
                        sx={{ overflowX: 'auto' }}
                        dangerouslySetInnerHTML={{ __html: shareholdingData }}
                      />}
                    </CardContent>
                  </StyledCard>
                </Grid>

                {/* Reports URLs Card */}
                <Grid item xs={12} md={6}>
                  <StyledCard>
                    <CardHeader
                      title="Financial Reports"
                      titleTypographyProps={{ variant: 'h6' }}
                    />
                    <CardContent>
                      <Stack spacing={2}>
                        {reportsUrls && reportsUrls.map((report, index) => (
                          <Box key={index}>
                            <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
                              {report.title} - {new Date(report.date).toLocaleDateString()}
                              <Link sx={{ marginLeft: 2 }} href={report.url} target="_blank" rel="noopener noreferrer">
                                View Report
                              </Link>
                            </Typography>
                            <Divider sx={{ my: 1 }} />
                          </Box>
                        ))}
                      </Stack>
                    </CardContent>
                  </StyledCard>
                </Grid>

                {/* Latest Announcements */}
                <Grid item xs={12} md={6}>
                  <StyledCard>
                    <CardHeader
                      title="Latest Announcements"
                      titleTypographyProps={{ variant: 'h6' }}
                    />
                    <CardContent sx={{ maxHeight: 600, overflowX: 'auto' }}>
                      <div className="space-y-4">
                        {latestAnnouncements && latestAnnouncements.map((announcement, index) => {
                          const date = new Date(announcement.date).toLocaleDateString('en-GB', {
                            day: '2-digit', month: 'short', year: 'numeric'
                          });
                          return (
                            <div key={index} className="space-y-2">
                              <div className="flex justify-between items-center">
                                <div className="flex flex-col">
                                  <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                                    {date}
                                  </Typography>
                                  <Typography variant="h6" color="textPrimary">
                                    {announcement.title}
                                  </Typography>
                                </div>
                              </div>
                              <Typography variant="body2" color="textSecondary">
                                {announcement.sub_title}
                              </Typography>
                              <a href={announcement.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                <Typography variant="body2" color="primary">
                                  View PDF
                                </Typography>
                              </a>
                              {index < latestAnnouncements.length - 1 && <Divider sx={{ marginY: 2 }} />}
                            </div>
                          );
                        })}
                      </div>
                    </CardContent>
                  </StyledCard>
                </Grid>

              </Grid>
              : <Typography variant="h4" sx={{ margin: 20 }}>
                Loading...!
              </Typography>}
          </>
          :
          <Typography variant="h4" sx={{ margin: 20 }}>
            Welcome, select stock to get started!
          </Typography>
        }

      </Box>
    </>
  );
};

export default Base;