import * as React from 'react';
import Box from '@mui/material/Box';
import Base from './components/base';
import './theme.css'
import { SnackbarProvider } from "notistack";
import { Grid } from '@mui/material';

export default function App() {
  const [showMobileWarning, setShowMobileWarning] = React.useState(false)
  React.useEffect(() => {
    if (window.innerWidth <= 950)
      setShowMobileWarning(true)
  }, [])
  return (

    <Box sx={{ m: 0 }}>
      <SnackbarProvider>
        {showMobileWarning ?
          <Grid container
            sx={{ m: 3 }}
            direction="row"
            justifyContent="center"
            alignItems="center">Sorry! App is released for desktop Version, mobile view coming soon...</Grid> : <Base />}

      </SnackbarProvider >
    </Box>

  );
}
