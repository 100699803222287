export const TICKER_LIST = [
	"ASIANPAINT",
	"EICHERMOT",
	"HEROMOTOCO",
	"ITC",
	"RELIANCE",
	"TATASTEEL",
	"DRREDDY",
	"SHRIRAMFIN",
	"INFY",
	"SUNPHARMA",
	"TCS",
	"MARUTI",
	"HCLTECH",
	"COALINDIA",
	"LTIM",
	"HDFCLIFE",
	"BAJAJ-AUTO",
	"BRITANNIA",
	"NESTLEIND",
	"HINDALCO",
	"LT",
	"TATACONSUM",
	"WIPRO",
	"TITAN",
	"BPCL",
	"BAJFINANCE",
	"JSWSTEEL",
	"ICICIBANK",
	"INDUSINDBK",
	"ONGC",
	"BHARTIARTL",
	"DIVISLAB",
	"SBILIFE",
	"BAJAJFINSV",
	"CIPLA",
	"GRASIM",
	"HINDUNILVR",
	"M&M",
	"TATAMOTORS",
	"APOLLOHOSP",
	"SBIN",
	"KOTAKBANK",
	"ADANIENT",
	"HDFCBANK",
	"POWERGRID",
	"AXISBANK",
	"NTPC",
	"TECHM",
	"ADANIPORTS",
	"ULTRACEMCO",
];
